.membership-active-urgency-signal {
    align-self: end;
    margin-top: -43px;
    margin-bottom: 15px;
    margin-right: 40px;
}

.membership-active-urgency-signal--one-size {
    align-self: end;
    margin-top: -59px;
}

.membership-urgency-signal {
    float: right;
    margin-right: 40px;
    margin-top: -162px;
}

.membership-urgency-signal--one-size {
    float: right;
    margin-top: -171px;
}

.kiffr-urgency-signal {
    float: right;
    margin-right: 40px;
    margin-top: -32px;
}

.kiffr-urgency-signal--one-size {
    float: right;
    margin-top: -35px;
}

.clearance-urgency-signal {
    float: right;
    margin-right: 40px;
    margin-top: -32px;
}

.reserve-urgency-signal {
    margin-top: 8px;
}
